.wrapper {
	background-color: var(--light-grey);
}

.wrapper[data-padding-top="true"] {
	padding-top: 120px;
}

.wrapper[data-padding-bottom="true"] {
	padding-bottom: 100px;
}

@media screen and (max-width: 768px) {
	.wrapper[data-padding-top="true"] {
		padding-top: 80px;
	}

	.wrapper[data-padding-bottom="true"] {
		padding-bottom: 80px;
	}
}
