.wrapper {
	background-color: var(--light-grey);
	padding-left: 20px;
	padding-right: 20px;
}

.wrapper[data-padding-top="true"] {
	padding-top: 120px;
}

.wrapper[data-padding-bottom="true"] {
	padding-bottom: 100px;
}

.container {
	display: flex;
	align-items: center;
}

.container[data-reverse="true"] {
	flex-direction: row-reverse;
}

.col {
	width: 50%;
}

.col:last-child {
	padding-left: 5%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 30px;
}

.container[data-reverse="true"] .col:last-child {
	padding-left: 0;
	padding-right: 5%;
}

.heading {
	color: var(--red);
}

@media screen and (max-width: 1024px) {
	.container {
		flex-direction: column;
		gap: 40px;
	}

	.container[data-reverse="true"] {
		flex-direction: column;
	}

	.col {
		width: 100%;
	}
}

@media screen and (max-width: 768px) {
	.wrapper[data-padding-top="true"] {
		padding-top: 80px;
	}

	.wrapper[data-padding-bottom="true"] {
		padding-bottom: 80px;
	}
}
